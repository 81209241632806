<template>
  <div class="page-classroom-view">
    <div class="my-page">
      <van-form style="background-color: transparent">
        <div class="my-card">
          <van-field
            readonly
            v-model="viewData.ChildrenName"
            name="ChildrenName"
            label="宝贝姓名"
          />
          <van-field
            readonly
            v-model="viewData.SexName"
            name="SexName"
            label="性别"
          />
          <van-field
            readonly
            v-model="viewData.IDCard"
            name="IDCard"
            label="宝贝身份证号"
          />
          <van-field
            readonly
            v-model="viewData.BirthDay"
            name="BirthDay"
            label="出生日期"
          />
          <van-field
            readonly
            v-model="viewData.JoinSchoolDate"
            name="JoinSchoolDate"
            label="入园时间"
          />
          <!-- <van-field
            readonly
            v-model="viewData.Session"
            name="Session"
            label="届次"
          /> -->
          <van-field
            readonly
            v-model="viewData.ClassName"
            name="ClassName"
            label="班级"
          />
          <van-field
            readonly
            v-model="viewData.BloodTypeName"
            name="BloodTypeName"
            label="血型"
          />
          <van-field
            readonly
            v-model="viewData.FirstContactName"
            name="FirstContactName"
            label="紧急联系人姓名"
          />
          <van-field
            readonly
            v-model="viewData.FirstContactCellPhone"
            name="FirstContactCellPhone"
            label="紧急联系人电话"
          />
          <van-field
            readonly
            v-model="viewData.FirstRelationship"
            name="FirstRelationship"
            label="紧急联系人和宝贝关系"
          />
          <van-field
            readonly
            v-model="viewData.MatherName"
            name="MatherName"
            label="母亲姓名"
          />
          <van-field
            readonly
            v-model="viewData.MatherCellPhone"
            name="MatherCellPhone"
            label="母亲电话"
          />
          <van-field
            readonly
            v-model="viewData.MatherIDCard"
            name="MatherIDCard"
            label="母亲身份证号"
          />
          <van-field
            readonly
            v-model="viewData.FatherName"
            name="FatherName"
            label="父亲姓名"
          />
          <van-field
            readonly
            v-model="viewData.FatherCellPhone"
            name="FatherCellPhone"
            label="父亲电话"
          />
          <van-field
            readonly
            v-model="viewData.FatherIDCard"
            name="FatherIDCard"
            label="父亲身份证号"
          />
          <van-field
            readonly
            v-model="viewData.Address"
            name="Address"
            label="家庭住址"
          />
          <van-field readonly name="uploadList" label="照片">
            <template #input>
              <img
                style="height:100px;width:100px"
                :src="viewData.uploadList ? viewData.uploadList[0].url : ''"
                alt=""
              />
            </template>
          </van-field>
        </div>
        <template>
          <div v-if="isAudit == 1" class="confirm-box"></div>
          <div v-if="isAudit == 3" class="confirm-box type1"></div>
          <div v-if="isAudit == 4" class="confirm-box type2"></div>
        </template>
      </van-form>
      <div v-if="isAudit == 1" class="btn-box">
        <van-button
          type="warning"
          style="width: 40%; float: left"
          @click="dialogVisible = true"
          >驳回</van-button
        >
        <van-button
          color="#ffe500"
          type="info"
          style="width: 40%; float: right"
          @click="handlePass"
          >通过</van-button
        >
      </div>
      <!-- 驳回弹窗 -->
      <van-dialog
        v-model="dialogVisible"
        title="驳回"
        :beforeClose="beforeClose"
        showCancelButton
      >
        <van-field
          required
          v-model="AuditDesc"
          name="LeaveDesc"
          rows="2"
          label="驳回原因"
          type="textarea"
          placeholder="请输入驳回原因"
        />
      </van-dialog>
    </div>
  </div>
</template>

<script>
import {
  Button,
  Form,
  Field,
  Uploader,
  Popup,
  Picker,
  DatetimePicker,
  Dialog,
  Collapse,
  CollapseItem,
  Step,
  Steps,
  Tag,
  Toast
} from "vant";
export default {
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [DatetimePicker.name]: DatetimePicker,
    [Dialog.Component.name]: Dialog.Component,
    [Toast.name]: Toast,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [Tag.name]: Tag
  },
  data() {
    return {
      minDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      id: null,
      viewData: {
        AccessoryPostList: [],
        flowList: [],
        ParentSign: ""
      },
      leaveTypeList: [],
      showPicker: false,
      showTime: false,
      showTime2: false,
      loading: false,
      dialogVisible: false,
      AuditDesc: "",
      activeNames: ["1"],
      isAudit: 0
    };
  },
  mounted() {
    if (this.$route.query.isAudit) {
      this.isAudit = this.$route.query.isAudit;
    }
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getDetail();
    }
  },
  methods: {
    getDetail() {
      this.$axios
        .get("/api/Children/Get", {
          ID: this.id
        })
        .then(res => {
          if (res.code === 200) {
            this.viewData = {
              ...res.data
            };
          }
        });
    },
    handlePass() {
      let that = this;
      this.$axios
        .post("/api/Children/Audit", {
          AuditDesc: "通过",
          ChildrenID: this.id,
          AuditState: 3
        })
        .then(res => {
          if (res.code === 200) {
            this.$toast.success(res.msg || "操作成功");
            setTimeout(() => {
              that.$router.replace("AuditSchedule");
            }, 1000);
          } else {
            this.$toast.fail(res.msg || "操作失败");
          }
        });
    },
    beforeClose(action, done) {
      let that = this;
      if (action === "confirm") {
        if (!this.AuditDesc) {
          this.$toast.fail("请填写驳回原因！");
          done(false);
        } else {
          this.$axios
            .post("/api/Children/Audit", {
              AuditDesc: this.AuditDesc,
              ChildrenID: this.id,
              AuditState: 4
            })
            .then(res => {
              if (res.code === 200) {
                this.$toast.success(res.msg || "操作成功");
                this.AuditDesc = "";
                done();
                setTimeout(() => {
                  that.$router.replace("AuditSchedule");
                }, 1000);
              } else {
                this.$toast.fail(res.msg || "操作失败");
              }
            });
        }
      } else {
        this.AuditDesc = "";
        done();
      }
    }
  }
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
